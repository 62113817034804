import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M3.5 12C3.5 10.896 4.396 10 5.5 10C6.604 10 7.5 10.896 7.5 12C7.5 13.104 6.604 14 5.5 14C4.396 14 3.5 13.104 3.5 12ZM12.5 10C11.396 10 10.5 10.896 10.5 12C10.5 13.104 11.396 14 12.5 14C13.604 14 14.5 13.104 14.5 12C14.5 10.896 13.604 10 12.5 10ZM19.5 10C18.396 10 17.5 10.896 17.5 12C17.5 13.104 18.396 14 19.5 14C20.604 14 21.5 13.104 21.5 12C21.5 10.896 20.604 10 19.5 10Z"
    }, null, -1)
  ])))
}
export default { render: render }