<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import {
  type NavigationItemType,
  navigationItemTypes,
} from '~/composables/useHomeLayoutOptions';
import IconHome from '~theme/assets/icons/outline/home.svg?component';
import IconMessages from '~theme/assets/icons/outline/message-circle.svg?component';
import IconMore from '~theme/assets/icons/outline/more-horizontal.svg?component';

defineProps<{ hasNotification?: boolean }>();

const links: Record<
  Exclude<NavigationItemType, 'unspecified'>,
  { icon: Component; label: string; location: RouteLocationRaw }
> = {
  home: {
    icon: IconHome,
    label: 'ホーム',
    location: '/home',
  },
  messages: {
    icon: IconMessages,
    label: 'メッセージ',
    location: '/my/messages',
  },
  misc: {
    icon: IconMore,
    label: 'その他',
    location: '/home/misc',
  },
} as const;

const options = useHomeLayoutOptions();
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="[$style.wrapper__content, $style.nav]">
      <NuxtLink
        v-for="t in navigationItemTypes"
        :key="t"
        :href="links[t].location"
        :class="[$style.nav__item, $style.item]"
      >
        <div
          :class="[
            $style.iconWrapper,
            {
              [$style['iconWrapper--notification']]:
                !!hasNotification && links[t].location === '/my/messages',
            },
          ]"
        >
          <component
            :is="links[t].icon"
            :class="[
              $style.item__icon,
              {
                [$style['item__icon--active']]:
                  t === options.activeNavigationItem,
              },
            ]"
          />
        </div>
        <p
          :class="[
            $style.item__label,
            {
              [$style['item__label--active']]:
                t === options.activeNavigationItem,
            },
          ]"
        >
          {{ links[t].label }}
        </p>
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="scss" module>
.wrapper {
  background-color: $color-surface-primary;
  width: 100%;
  display: flex;
  justify-content: center;
  &__content {
    flex: 1;
  }
}

.nav {
  height: 60px;
  display: flex;
  max-width: 600px;

  &__item {
    flex: 1;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  &__label {
    font-size: 12px;
    line-height: 1em;
    font-weight: bold;
    color: $color-text-and-object-high-emphasis;
    &--active {
      color: $color-text-and-object-accent-primary;
    }
  }

  &__icon {
    fill: $color-text-and-object-high-emphasis;
    &--active {
      fill: $color-text-and-object-accent-primary;
    }
  }
}

.iconWrapper {
  position: relative;
  &--notification {
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      right: -10px;
      height: 6px;
      width: 6px;
      background-color: $color-text-and-object-accent-red;
      border-radius: 100vh;
    }
  }
}
</style>
