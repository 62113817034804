import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "25",
  height: "24",
  viewBox: "0 0 25 24",
  fill: "currentColor",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M19.49 20.0004H16.5V13.0004C16.5 12.4474 16.052 12.0004 15.5 12.0004H9.5C8.947 12.0004 8.5 12.4474 8.5 13.0004V20.0004H5.5L5.506 11.5834L12.498 4.43245L19.5 11.6244L19.49 20.0004ZM10.5 20.0004H14.5V14.0004H10.5V20.0004ZM20.924 10.1854L13.215 2.30145C12.838 1.91645 12.162 1.91645 11.785 2.30145L4.075 10.1864C3.71 10.5614 3.5 11.0854 3.5 11.6244V20.0004C3.5 21.1034 4.347 22.0004 5.388 22.0004H9.5H15.5H19.611C20.652 22.0004 21.5 21.1034 21.5 20.0004V11.6244C21.5 11.0854 21.29 10.5614 20.924 10.1854Z"
    }, null, -1)
  ])))
}
export default { render: render }