<script setup lang="ts">
import AppLayoutBase from '~/components/organisms/AppLayoutBase/AppLayoutBase.vue';
import HomeBottomNavigation from '~/components/organisms/HomeBottomNavigation/HomeBottomNavigation.vue';
import HomeHeader from '~/components/organisms/HomeHeader/HomeHeader.vue';
import MailActivationAlert from '~/components/organisms/MailActivationAlert/MailActivationAlert.vue';
import { useHomeLayoutOptions } from '~/composables/useHomeLayoutOptions';

const isAuthenticated = useClientSideOnlyComputed(
  false,
  () => useAuthenticationState().isAuthenticated
);
const options = useHomeLayoutOptions();
const showBottomNavigation = useClientSideOnlyComputed(false, () =>
  computed(() => options.value.showBottomNavigation)
);
const { notifications } = useNotifications();
const hasNotification = computed(() => (notifications.value?.length ?? 0) > 0);

const { scriptURL } = useReCaptcha();
useHead({
  script: [
    {
      src: scriptURL,
    },
  ],
});
</script>

<template>
  <AppLayoutBase :is-authenticated="isAuthenticated">
    <div :class="$style.layout">
      <HomeHeader
        :class="$style.layout__header"
        :has-notification="hasNotification"
      />
      <ClientOnly><MailActivationAlert ref="mailAlert" /></ClientOnly>
      <div :class="$style.layout__content">
        <slot />
      </div>
      <HomeBottomNavigation
        v-if="showBottomNavigation"
        :has-notification="hasNotification"
        :class="$style.layout__footer"
      />
    </div>
  </AppLayoutBase>
</template>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>

<style lang="scss" module>
.layout {
  position: relative;
  min-height: 100vh;
  min-height: 100dvh;
  flex-direction: column;
  display: flex;

  @include create-stacking-context;

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;

    & > * {
      flex-grow: 1;
    }
  }

  &__header {
    position: sticky;
    border-bottom: 1px solid $color-border-low-emphasis;
    z-index: 1;
    top: 0;
  }

  &__footer {
    position: sticky;
    border-top: 1px solid $color-border-low-emphasis;
    z-index: 1;
    bottom: 0;
  }
}
</style>
