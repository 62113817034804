<script setup lang="ts">
import AppHeaderNotificationForSp from '../AppHeader/AppHeaderNotificationForSp.vue';
import IconBell from './bell.svg?component';
import ImgLogo from './logo.svg?component';
import IconArrowLeft from 'theme/components/atoms/icons/IconArrowLeft.vue';
import FadeInTransition from 'theme/components/transitions/FadeInTransition.vue';

defineProps<{
  hasNotification: boolean;
}>();

const options = useHomeLayoutOptions();
const onClickBack = () => {
  const dest = options.value.backButtonDestination;
  if (!dest) return;
  navigateTo(dest);
};
const isBackButtonHidden = computed(() => !options.value.backButtonDestination);
const isAuthenticated = useClientSideOnlyComputed(
  false,
  () => useAuthenticationState().isAuthenticated
);
const isNotificationsOpened = ref(false);
</script>

<template>
  <div :class="$style.wrapper">
    <div :class="$style.wrapper__content">
      <div :class="$style.header">
        <IconArrowLeft
          :class="[
            $style.header__back,
            { [$style['header__back--hidden']]: isBackButtonHidden },
          ]"
          @click="onClickBack"
        />
        <p v-if="options.title" :class="$style.header__title">
          {{ options.title }}
        </p>
        <ImgLogo v-else />
        <div
          :class="[
            $style.header__bell,
            {
              [$style['header__bell--hidden']]: !isAuthenticated,
              [$style['header__bell--active']]: !!hasNotification,
            },
          ]"
        >
          <IconBell @click="() => (isNotificationsOpened = true)" />
          <FadeInTransition>
            <AppHeaderNotificationForSp
              v-if="isAuthenticated && isNotificationsOpened"
              :class="[$style.header__notifications]"
              @close-notification="() => (isNotificationsOpened = false)"
            />
          </FadeInTransition>
        </div>
      </div>
    </div>
    <div id="header-attachment" :class="$style.attachment" />
  </div>
</template>

<style module lang="scss">
.wrapper {
  width: 100%;
  background-color: $color-surface-primary;
  @include create-stacking-context;

  &__content {
    position: relative;
    background-color: $color-surface-primary;
    width: 100%;
    z-index: 2;
  }

  &__attachment {
    position: relative;
    width: 100%;
    z-index: 1;
  }
}

.header {
  margin: 0 auto;
  display: flex;
  width: 100%;
  max-width: 600px;
  height: 48px;
  padding: 0 16px;
  align-items: center;
  justify-content: space-between;

  &__back {
    font-size: 24px;
    &--hidden {
      visibility: hidden;
    }
  }

  &__title {
    font-weight: bold;
    line-height: 1em;
    font-size: 18px;
  }

  &__bell {
    position: relative;
    width: 24px;
    height: 24px;

    &--hidden {
      visibility: hidden;
    }

    &--active {
      &::before {
        content: '';
        position: absolute;
        border-radius: 100vh;
        background-color: $color-text-and-object-accent-red;
        width: 6px;
        height: 6px;
        top: -2px;
        right: -2px;
      }
    }
  }

  &__notifications {
    position: absolute;
    width: 280px;
    top: 100%;
    right: 0;
    z-index: 1;
  }
}
</style>
