import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M5.5146 15.9998L6.6946 14.8178C7.0726 14.4398 7.2806 13.9378 7.2806 13.4038V8.72683C7.2806 7.36983 7.8706 6.07283 8.9006 5.17083C9.9386 4.26083 11.2606 3.86083 12.6376 4.04183C14.9646 4.35083 16.7196 6.45483 16.7196 8.93683V13.4038C16.7196 13.9378 16.9276 14.4398 17.3046 14.8168L18.4856 15.9998H5.5146ZM13.9996 18.3408C13.9996 19.2398 13.0836 19.9998 11.9996 19.9998C10.9156 19.9998 9.9996 19.2398 9.9996 18.3408V17.9998H13.9996V18.3408ZM20.5206 15.2078L18.7196 13.4038V8.93683C18.7196 5.45583 16.2176 2.49883 12.8996 2.05983C10.9776 1.80383 9.0376 2.39083 7.5826 3.66683C6.1186 4.94883 5.2806 6.79283 5.2806 8.72683L5.2796 13.4038L3.4786 15.2078C3.0096 15.6778 2.8706 16.3768 3.1246 16.9898C3.3796 17.6038 3.9726 17.9998 4.6366 17.9998H7.9996V18.3408C7.9996 20.3588 9.7936 21.9998 11.9996 21.9998C14.2056 21.9998 15.9996 20.3588 15.9996 18.3408V17.9998H19.3626C20.0266 17.9998 20.6186 17.6038 20.8726 16.9908C21.1276 16.3768 20.9896 15.6768 20.5206 15.2078Z",
      fill: "#030E1A"
    }, null, -1)
  ])))
}
export default { render: render }